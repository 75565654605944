#container figure {
  display: inline-block;
  margin: 2rem;
}

.plotDiv {
  margin: 2rem;
  text-align: center;

  display: inline-grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto;
  grid-template-areas:
    "header header"
    "label main"
    "footer footer";
  align-items: center;
}

.plotDiv h1 {
  grid-column: 1 / 3;
  display: block;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;

  margin-block-start: 0 !important;
  margin-block-end: 0.5rem !important;
}

.horiz {
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.ydiv {
  text-align: center;
  text-orientation: sideways-right;
}

.yaxis {
  writing-mode: vertical-lr;
  rotate: 180deg;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.75rem;
}

.xdiv {
  grid-column: 1 / 3;
  text-align: center;
  display: block;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.5rem;
}

.fxdiv {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.6rem;
}

.fxdiv h3 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.plotDiv > figure {
  margin: 0 !important;
}

/* .plot-d6a7b5-ramp {
    /* world map legend 
    margin-left: 150px;
  } */

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: top;
  overflow: hidden;
}

.svg-content {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

#container figure svg .plot-d6a7b5 {
  margin: 100px;
}

.plot-d6a7b5-swatches {
  margin-left: 50px;
}

/* #container {
    margin: auto;
  } */

.plot-d6a7b5-swatches-wrap {
  display: inline-grid !important;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
}

@media (max-width: 600px) {
  .plot-d6a7b5-swatches-wrap {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: start; /* Adjust as needed */
  }
  .plot-d6a7b5-swatches {
    margin-left: 0px;
  }
}
